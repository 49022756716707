<template>
    <div>
        <p class="title is-5 has-text-centered">Sections</p>
        <hr>

        <router-link tag="div" :to="{name:'customerHome'}" class="section-select columns" :class="{'active': $route.name === 'customerHome'}">
            <div class="column is-2 is-offset-1">
                <i class="fas fa-home"/>
            </div>
            <div class="column is-7">Home</div>
        </router-link>

        <router-link tag="div" :to="{name:'customerPolicies'}" class="section-select columns" :class="{'active': $route.name === 'customerPolicies' || $route.name === 'customerPolicy'}">
            <div class="column is-2 is-offset-1">
                <i class="fas fa-list-alt"/>
            </div>
            <div class="column is-7">My Policies</div>
        </router-link>

        <router-link tag="div" :to="{name:'customerQuotes'}" class="section-select columns" :class="{'active': $route.name === 'customerQuotes'}">
            <div class="column is-2 is-offset-1">
                <i class="far fa-list-alt"/>
            </div>
            <div class="column is-7">My Quotes</div>
        </router-link>

        <router-link tag="div" :to="{name:'customerProfile'}" class="section-select columns" :class="{'active': $route.name === 'customerProfile'}">
            <div class="column is-2 is-offset-1">
                <i class="fas fa-user"/>
            </div>
            <div class="column is-7">My Profile</div>
        </router-link>

        <router-link tag="div" :to="{name:'customerProfileEdit'}" class="section-select columns" :class="{'active': $route.name === 'customerProfileEdit'}">
            <div class="column is-2 is-offset-1">
                <i class="fas fa-user-edit"/>
            </div>
            <div class="column is-7">Edit Profile</div>
        </router-link>

        <router-link tag="div" :to="{name:'customerMedicalScreening'}" class="section-select columns" :class="{'active': $route.name === 'customerMedicalScreening'}">
            <div class="column is-2 is-offset-1">
                <i class="fas fa-notes-medical"/>
            </div>
            <div class="column is-7">Medical Screening</div>
        </router-link>

        <router-link tag="div" :to="{name:'customerPolicyWording'}" class="section-select columns" :class="{'active': $route.name === 'customerPolicyWording'}">
            <div class="column is-2 is-offset-1">
                <i class="far fa-file-alt"/>
            </div>
            <div class="column is-7">Policy Wordings</div>
        </router-link>

        <router-link tag="div" :to="{name:'claim'}" class="section-select columns" :class="{'active': $route.name === 'claim'}">
            <div class="column is-2 is-offset-1">
                <i class="far fa-file-alt"/>
            </div>
            <div class="column is-7">Make a Claim</div>
        </router-link>

        <router-link tag="div" :to="{name:'faqs'}" class="section-select columns">
            <div class="column is-2 is-offset-1">
                <i class="fas fa-file-alt"/>
            </div>
            <div class="column is-7">FAQs</div>
        </router-link>

        <router-link tag="div" :to="{name:'customerContactUs'}" class="section-select columns" :class="{'active': $route.name === 'customerContactUs'}">
            <div class="column is-2 is-offset-1">
                <i class="fas fa-phone"/>
            </div>
            <div class="column is-7">Contact Us</div>
        </router-link>

        <router-link tag="div" :to="{name:'customerFeedback'}" class="section-select columns" :class="{'active': $route.name === 'customerFeedback'}">
            <div class="column is-2 is-offset-1">
                <i class="fas fa-comments"/>
            </div>
            <div class="column is-7">Feedback</div>
        </router-link>

        <div class="section-select columns">
            <div class="column is-2 is-offset-1">
                <i class="fas fa-star"/>
            </div>
            <div class="column is-7"><a href="https://uk.trustpilot.com/evaluate/covered2go.co.uk" target="_blank" style="margin: 0; padding: 0; color: white;">Leave a Review</a></div>
        </div>

        <router-link tag="div" :to="{name:'customerChangePassword'}" class="section-select columns" :class="{'active': $route.name === 'customerChangePassword'}">
            <div class="column is-2 is-offset-1">
                <i class="fas fa-key"/>
            </div>
            <div class="column is-7">Change Password</div>
        </router-link>

        <div class="section-select button-select columns" @click="logout">
            <div class="column is-2 is-offset-1">
                <i class="fas fa-sign-out-alt"></i>
            </div>
            <div class="column is-7">Logout</div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from "vuex";

    export default {
        name: "CustomerLeftPanel",
        methods: {
            ...mapActions({
                setCustomer: 'setCustomer',
            }),
            // Logout
            logout() {
                this.setCustomer(null);
                this.$router.push({name:'home'});
            }
        }
    }
</script>

<style lang="scss" scoped>
    .section-select {
        background-color: $c2g_orange;
        border-radius: 5px;
        text-align: center;
        color: white;
        padding: 5px;
        cursor: pointer;
        font-weight: bold;
        margin-bottom: 5px;
        .column {
            padding: 0;
        }
    }
    .section-select:hover {
        background-color: #cd7728;
    }
    .active {
        background-color: #cd7728;
    }
    .columns {
        margin-bottom: 20px;
    }

    .button-select {
        background-color: #0D4F9C;
    }
    .button-select:hover {
        background-color: #0d407d;
    }


</style>