<template>
    <div class="box main">
        <div class="level-item header-section">
            <!-- <h4 class="title is-2 page-title">Customer Hub</h4> -->
        </div>
        <div class="columns">
            <div class="column">
                <div class="box">
                    <customer-left-panel/>
                </div>
            </div>
            <div class="column is-9">
                <router-view/>
            </div>
        </div>
    </div>
</template>

<script>
    import CustomerLeftPanel from "../components/customer-hub/sections/CustomerLeftPanel";
    import { mapGetters } from "vuex";
    import { policyAPI } from "../mixins/policy-api";

    export default {
        name: "CustomerHub",
        components: {
            CustomerLeftPanel
        },
        computed: {
            ...mapGetters({
                customer: 'customer',
            })
        },
        mixins: [policyAPI],
    }
</script>

<style lang="scss" scoped>
    .header-section {
        margin-bottom: 30px;
    }
    .main {
        min-height: 500px;
    }
</style>